import axios from "axios";
import { getAccountsAndCardsResponses } from "../../../utils/data/responseCodeMessages.js";

const { REACT_APP_API_URL } = process.env;

export const getAccountsAndCards = async (payload) => {
  const { token } = payload;
  const header = { headers: { Authorization: token } };

  const res = await axios
    .get(`${REACT_APP_API_URL}/payment/accountsandcards`, header)
    .then((response) => {
      if (response?.data) {
        return response.data;
      }
      return response;
    })
    .catch((error) => {
      const status = error?.response?.status || error?.request?.status;
      const errorResponse = getAccountsAndCardsResponses[status]
        || getAccountsAndCardsResponses["default"];
      throw errorResponse;
    });

  return res;
};
