import axios from "axios";
import { postCardResponses } from "../../../utils/data/responseCodeMessages";

const { REACT_APP_API_URL } = process.env;

export const postCard = async (payload) => {
  const { token } = payload;
  const header = { headers: { Authorization: token } };

  delete payload.token;

  const res = await axios
    .post(`${REACT_APP_API_URL}/card`, payload, header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const status = error?.response?.status || error?.request?.status;
      const data = error?.response?.data;
      const errorResponse = postCardResponses()[data.message]
        || postCardResponses()[status]
        || postCardResponses()["default"];
      throw errorResponse;
    });

  return res;
};
