import React from "react";
import { creditCardBrand, CreditCardForm, MaskTextField } from "@swbc/swivel-components";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../components";
import Title from "../../../components/Title";
import { useTransactionDispatch, transactionTypes, useSelectPaymentMethodDispatch, selectPaymentMethodTypes } from "../../../context";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics";
import { useAccountsAndCardsData } from "../../../services/payments/lib/hooks";
import { postCard } from "../../../services/storedCards/api/postCard";
import { REGISTERED } from "../../../utils/constants/constants";
import { getAccountLastFour } from "../../../utils/helpers/handleAccounts";

const AddCardPage = () => {

  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  const { details } = useSelector(institutionState);
  const { flow, authToken } = useSelector(authState);
  const { actions, categories, trackGtagEvent } = useGoogleAnalytics();

  const selectPaymentDispatch = useSelectPaymentMethodDispatch();
  const dispatch = useTransactionDispatch();
  const navigate = useNavigate();

  const { data: accountsAndCardsData } = useAccountsAndCardsData();

  const mutation = useMutation({
    mutationFn: postCard,
    onSuccess: (data) => {
      const cardData = {
        type: "card",
        last_4: data?.card_details?.card_last4,
        label: data?.display_name,
        ...data
      };
      dispatch({
        type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        value: cardData
      });
      selectPaymentDispatch({
        type: selectPaymentMethodTypes.ADD_PAYMENT_METHOD,
        value: cardData
      });
      trackGtagEvent(actions.STORE_CARD, categories.ATTEMPT_SUCCESS);
      navigate("/make-payment");
    },
    onError: () => {
      trackGtagEvent(actions.STORE_CARD, categories.ATTEMPT_FAILURE);
    }
  });

  const handleAddCardSubmit = (form) => {
    const cardBrand = creditCardBrand(form.data.card_number);
    const [month, year] = form.data.card_expiry_date.split("/");
    const expMonth = parseInt(month, 10);
    const expYear = 2000 + parseInt(year, 10);

    if (form.data.store_card) {
      const payload = {
        card_details: {
          card_number: form.data.card_number,
          card_brand: cardBrand,
          expiration_month: expMonth,
          expiration_year: expYear
        },
        display_name: cardBrand,
        token: authToken?.value
      };
      mutation.mutate(payload);
    } else {
      const cardData = {
        type: "card",
        last_4: getAccountLastFour(form.data.card_number),
        label: cardBrand,
        ...form.data
      };
      dispatch({
        type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        value: cardData
      });
      selectPaymentDispatch({
        type: selectPaymentMethodTypes.ADD_PAYMENT_METHOD,
        value: cardData
      });
      navigate("/make-payment");
    }
  };

  const acceptedCardTypes = details?.accepted_card_types;
  const nativeBinList = details?.native_bin_list;
  const nativeBinListError = details?.native_bin_list_error_text;
  const displayCheckbox = flow === REGISTERED;
  const maxCardsReached = accountsAndCardsData?.cards?.results.length > 4;

  return (
    <>
      <Title title="Add Card Details" />
      <Alert message={mutation?.isError && mutation?.error?.response} severity={mutation?.error?.severity} />
      <CreditCardForm
        // eslint-disable-next-line no-unused-vars
        DateField={({ value, ...props }) => <MaskTextField mask="00/00" {...props} />}
        disableGutters={true}
        onSubmit={handleAddCardSubmit}
        displayCheckbox={displayCheckbox}
        isLoading={mutation.isLoading}
        maxCardsReached={maxCardsReached}
        options={{
          whitelistCreditCardBrands: acceptedCardTypes,
          blacklistCreditCardBins: nativeBinList,
          blacklistCreditCardBinsErrorMessage: nativeBinListError
        }}
      />
    </>
  );
};

export default AddCardPage;
