import React, { createContext, useContext, useReducer, useEffect } from "react";
import types from "./actions";

const initialState = {
  entryPoint: "",
  availablePaymentMethods: {
    achAccounts: [],
    cards: []
  }
};

export const SelectPaymentMethodContext = createContext(initialState);
export const SelectPaymentMethodDispatch = createContext();

const selectPaymentMethodReducer = (state, action) => {
  switch (action.type) {
    case types.SAVE_ENTRY_POINT:
      return {
        ...state,
        entryPoint: action.value
      };
    case types.UPDATE_AVAILABLE_ACH: {
      return {
        ...state,
        availablePaymentMethods: {
          ...state.availablePaymentMethods,
          achAccounts: action.value
        }
      };
    }
    case types.UPDATE_AVAILABLE_CARD: {
      return {
        ...state,
        availablePaymentMethods: {
          ...state.availablePaymentMethods,
          cards: action.value
        }
      };
    }
    case types.UPDATE_IS_APPLE_PAY_ENABLED:
      return {
        ...state,
        isApplePayEnabled: action.value
      };
    case types.ADD_PAYMENT_METHOD:
      return {
        ...state,
        availablePaymentMethods: {
          ...state.availablePaymentMethods,
          cards: [...state.availablePaymentMethods.cards, action.value]
        }
      };
    default:
      break;
  }
};

const useSelectPaymentMethodContext = () => {
  const context = useContext(SelectPaymentMethodContext);
  if (context === undefined) {
    throw new Error("SelectPaymentMethodContext must be used within SelectPaymentMethodProvider");
  }
  return context;
};

const useSelectPaymentMethodDispatch = () => {
  const context = useContext(SelectPaymentMethodDispatch);
  if (context === undefined) {
    throw new Error("SelectPaymentMethodDispatch must be used within SelectPaymentMethodProvider");
  }
  return context;
};

const useACHAccountUpdate = ({ paymentMethodDispatch, getAccounts }) => {
  useEffect(() => {
    const accounts = getAccounts?.response?.results;
    const achAccounts = accounts?.map((account) => {
      return {
        ...account,
        type: "ach",
        label: account?.banking_details?.name_of_bank
          ? account?.banking_details?.name_of_bank
          : "account",
        banking_details: {
          ...account.banking_details,
          type: account?.type
        }
      };
    });
    paymentMethodDispatch({
      type: types.UPDATE_AVAILABLE_ACH,
      value: achAccounts
    });
  }, [getAccounts]);
};

const SelectPaymentMethodProvider = ({ children }) => {
  const [state, dispatch] = useReducer(selectPaymentMethodReducer, initialState);

  return (
    <SelectPaymentMethodContext.Provider value={state}>
      <SelectPaymentMethodDispatch.Provider value={dispatch}>
        {children}
      </SelectPaymentMethodDispatch.Provider>
    </SelectPaymentMethodContext.Provider>
  );
};

export {
  useSelectPaymentMethodContext,
  useSelectPaymentMethodDispatch,
  SelectPaymentMethodProvider,
  useACHAccountUpdate,
  types
};
