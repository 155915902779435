import React, { useState } from "react";
import { Stack } from "@mui/material";
import { Loader, PaymentSourceSelectionList, List } from "@swbc/swivel-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { Typography, Grid, Divider, Alert, Box } from "../../components/mui";
import { deleteAccount } from "../../services/accounts/api/deleteAccount.js";
import { useHandleDeleteSavedPaymentMethod, useAccountsAndCardsData } from "../../services/payments/lib/hooks.js";
import { deleteCard } from "../../services/storedCards/api/deleteCard.js";
import { sortAccountsAndCards } from "../../utils/helpers/accountsAndCardsHelpers.js";

export const ProfileSavedAccounts = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [error, setError] = useState(null);

  const {
    data: accountsAndCardsData,
    refetch,
    isLoading: accountsAndCardsLoading,
    isRefetching: accountsAndCardRefetching
  } = useAccountsAndCardsData(setError);


  const handleAddPaymentMethod = () => {
    navigate("/add-or-edit-payment-method");
  };

  const cardMutation = useMutation({
    mutationFn: deleteCard,
    onSuccess: async () => {
      await refetch();
      queryClient.invalidateQueries(["getAccountsAndCards"]);
    },
    onError: () => {
      setError({ message: "There was a problem deleting your card", severity: "error" });
    }
  });

  const achMutation = useMutation({
    mutationFn: deleteAccount,
    onSuccess: () => {
      refetch();
      queryClient.invalidateQueries(["getAccountsAndCards"]);
    },
    onError: () => {
      setError({ message: "There was a problem deleting your account", severity: "error" });
    }
  });

  const deleteSavedPaymentMethod = useHandleDeleteSavedPaymentMethod({ error, setError, cardMutation, achMutation });

  const { accounts, cards } = sortAccountsAndCards(accountsAndCardsData?.accounts?.results, accountsAndCardsData?.cards?.results) || [];

  const cardList = cards?.map((item) => {
    const month = item?.card_details?.expiration_month.toString().padStart(2, "0");
    const year = item?.card_details?.expiration_year.toString().slice(-2);

    return {
      id: item?.id,
      paymentType: "card",
      cardBrand: item?.display_name,
      cardExpiry: `${month}/${year}`,
      primaryText: item?.display_name,
      secondaryText: item?.card_details?.card_last4,
      details: {
        ...item,
        label: item?.display_name,
        type: "card",
        last_4: item?.card_details?.card_last4
      }
    };
  });

  const bankList = accounts?.map((item) => {
    return {
      id: item?.id,
      paymentType: "ach",
      primaryText: item?.banking_details?.name_of_bank,
      secondaryText: item?.last_4,
      details: {
        ...item,
        label: item?.banking_details?.name_of_bank,
        type: "ach"
      }
    };
  });

  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: "1rem"
      }}
    >
      <Typography variant="h2" component="h2">Saved Payment Methods</Typography>
      <Stack
        divider={<Divider sx={{ width: "100%", margin: "auto" }} aria-hidden="true" />}
        sx={{ margin: "0px", padding: "0px", display: "grid" }}
      >
        {accountsAndCardsLoading || accountsAndCardRefetching || cardMutation.isLoading || achMutation.isLoading ? (
          <Box role="group">
            <Box sx={{ position: "relative", height: "5rem" }}>
              <Loader size="2rem" />
            </Box>
            <Typography variant="h2" sx={{ textAlign: "center" }}>
              <output aria-live="polite">Loading...</output>
            </Typography>
          </Box>
        ) : (
          <>
            <Alert message={cardMutation?.isSuccess && cardMutation?.data?.message} severity={cardMutation?.status} />
            <Alert message={achMutation?.isSuccess && achMutation?.data?.message} severity={achMutation?.status} />
            <Alert message={error?.message} severity={error?.severity} />
            {
              (bankList && !bankList.length) && (cardList && !cardList.length)
                ? <Alert message="No saved payment methods have been created" severity="info" />
                : null
            }
            <Alert message={error?.message} severity={error?.severity} />
            <List sx={{ padding: "0", border: "2px solid #ddd", borderRadius: "5px" }}>
              {
                bankList?.map((account, index) => (
                  <PaymentSourceSelectionList
                    key={account.id}
                    id={account.id}
                    paymentType={account.paymentType}
                    primaryText={account.primaryText}
                    secondaryText={account.secondaryText}
                    deletePaymentMethod={() => deleteSavedPaymentMethod(account)}
                    loadingItemId={null}
                    selectable={false}
                    selected={null}
                    isLastItem={cardList.length < 1 && index === bankList.length - 1}
                  />
                ))
              }
              {
                cardList?.map((card, index) => {
                  return (
                    <PaymentSourceSelectionList
                      key={card.id}
                      id={card.id}
                      paymentType={card.paymentType}
                      primaryText={card.primaryText}
                      secondaryText={card.secondaryText}
                      cardBrand={card.cardBrand}
                      cardExpiry={card.cardExpiry}
                      deletePaymentMethod={() => deleteSavedPaymentMethod(card)}
                      loadingItemId={null}
                      selectable={false}
                      selected={null}
                      isLastItem={index === cardList.length - 1}
                    />
                  );
                })
              }
            </List>
            <Grid item xs={12} paddingTop="1.5rem" container spacing={1} direction="row-reverse">
              <Grid item xs={12} md={6}>
                <Button
                  color="secondary"
                  onClick={handleAddPaymentMethod}
                >
                  ADD PAYMENT METHOD
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
    </div>
  );
};
