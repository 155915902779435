import axios from "axios";
import { deleteSavedAccountResponses } from "../../../utils/data/responseCodeMessages";

const { REACT_APP_API_URL } = process.env;

export const deleteAccount = async (payload) => {
  const { token, id } = payload;
  const header = { headers: { Authorization: token } };

  delete payload?.token;

  const response = await axios
    .delete(`${REACT_APP_API_URL}/account/${id}`, header)
    .then((res) => {
      if (res?.status === 200) {
        return deleteSavedAccountResponses()[res?.status];
      }
      return res;
    })
    .catch((error) => {
      const status = error?.response?.status || error?.request?.status;
      const data = error?.response?.data;
      const errorResponse = deleteSavedAccountResponses()[status]
        || deleteSavedAccountResponses()[data.errorMessage]
        || deleteSavedAccountResponses()["default"];
      throw errorResponse;
    });

  return response;
};
